@use "../../assets/sass/abstracts/variables" as *;

header {
  position: absolute;
  .navbar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 8vh;
    background-color: $color-background;
    border-bottom: $color-green-opacity 1px solid;
    z-index: 3;
    .navbar__logo {
      height: 50%;
      padding: 0 3rem;
    }

    .navbar__links {
      list-style-type: none;
      color: $color-green;
      display: none;
      width: 75%;
      @media only screen and (min-width: $desktop-breakpoint) {
        display: flex;
        justify-content: space-around;
        text-align: end;
      }
      .navbar__link > a {
        color: $color-green;
      }
    }
    .navbar__hamburger {
      padding-right: 2rem;
      height: 4vmin;
      display: flex;
      justify-content: flex-end;
      @media only screen and (min-width: 1024px) {
        display: none;
      }
    }
  }

  .nav__draw-wrap {
    z-index: 0;
    min-height: 500vh;
    min-width: 100vw;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
    animation: 1s ease-out 0s 1 fadeIn;
    z-index: 2;
    position: fixed;
    animation-fill-mode: forwards;
  }

  .nav__draw {
    margin-top: 8vh;
    gap: 2rem;
    background-color: $color-background;
    color: $color-green;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    width: 100vw;
    top: 8vh;
    padding: 2rem;
    border-bottom-left-radius: 1rem;
    animation: 1s ease-out 0s 1 slideInFromLeft;
    z-index: 2;
    animation-fill-mode: forwards;

    .nav__draw-link {
      size: 2rem;
    }
    .nav__draw-link > a {
      color: $color-green;
    }
  }
  @keyframes slideInFromLeft {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

@keyframes fadeOut {
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}
