@use "../../assets/sass/abstracts/variables" as *;
@use "../../assets/sass/abstracts/mixin" as *;
@use "../../assets/sass/base/resets" as *;
@use "../../assets/sass/base/typography" as *;

.footer {
  grid-area:9/1/8/13;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color-green-opacity;
  .footer__icons {
    width: 75%;
    display: flex;
    justify-content: space-around;
    padding: 1rem;
  }
  .footer__text {
    color: $color-background;
    padding: 1rem;
  }
}
