@use "../abstracts/variables" as *;

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-accent;
}

p {
  font-family: $font-body;
  font-size: 1rem;
}
h1 {
  font-family: $font-accent;
  font-size: 2.5rem;
  font-weight: 400;
  font-style: italic;
  @media only screen and (min-width: $desktop-breakpoint) {
    font-size: 5rem;
    text-align: left;
    white-space: nowrap;
  }
}

h2 {
  font-family: $font-accent;
  font-size: 1.25rem;
  font-weight: 400;
  @media only screen and (min-width: $desktop-breakpoint) {
    font-size: 2rem;
    text-align: center;
  }
}
h3 {
  font-family: $font-accent;
  font-size: 2.5rem;
}

h3 {
  font-family: $font-accent;
  font-size: 2.5rem;
}

h4 {
  font-family: $font-accent;
  color: $color-green;
  font-size: 2rem;
}

a {
  text-decoration: none;
  font-family: $font-body;
}
.page__heading {
  color: $color-green;
  grid-area: 2/3/2/10;
  align-self: center;
}
