@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;1,500;1,700;1,765&display=swap");

$color-black: #1e1e24;
$color-green: #3a5751;
$color-green-opacity: #3a575196;
$color-pink: #fcdad8;
$color-background: #faf4ed;
$color-blue: #a9bfde;
$color-grey: #d3d2d2;
$color-dark-grey: #3b3b3bce;

$font-body: "Montserrat", sans-serif;
$font-accent: "Playfair Display", serif;

$mobile-breakpoint: 320px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1024px;
$large-desktop-breakpoint: 1440px;
$extra-large-desktop-breakpoint: 2560px;

$border-radius: 1.5rem;
