@use "../abstracts/variables" as *;
.page {
  background-color: $color-background;
  width: 100vw;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
  grid-template-rows: repeat(8, 1fr);
}
