@use "../../assets/sass/abstracts/variables" as *;
@use "../../assets/sass/layout/layout" as *;
@use "../../assets/sass/abstracts/mixin" as *;
@use "../../assets/sass/base/resets" as *;

#contact {
  .contact__heading {
    grid-area: 1/3/2/10;
    align-self: center;
    @media only screen and (min-width: $desktop-breakpoint) {
      grid-area: 2/3/2/10;
    }
  }
}
