@use "../../assets/sass/abstracts/variables" as *;
@use "../../assets/sass/abstracts/mixin" as *;
@use "../../assets/sass/base/resets" as *;
@use "../../assets/sass/base/typography" as *;

.about {
  grid-area: 2/3/7/11;
  align-items: center;
  @media only screen and (min-width: $desktop-breakpoint) {
    grid-area: 3/3/7/11;
  }
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  .about__text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .about__button {
      width: 12rem;
      text-align: center;
    }
  }
  &__images{
    max-height: 200px;
    padding-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
    @media screen and (min-width: $desktop-breakpoint) {
      max-height: 500px;
    }
  }
  &__image{
    max-height: 100%;
  }
}
