@use "../../assets/sass/abstracts/mixin" as *;
@use "../../assets/sass/abstracts/variables" as *;

.carousel {
  grid-area: 3/3/9/11;
  .carousel__image-container {
    position: relative;
    display: flex;
    gap: 0.25rem;

    .carousel__flower {
      position: absolute;
      z-index: 1;
    }

    .carousel__flower--pink {
      width: 7rem;
      left: -3rem;
      top: 6rem;
    }
    .carousel__flower--petals {
      width: 3rem;
      left: -2rem;
      top: 1rem;
      transform: rotate(-20deg);
    }
    .carousel__flower--green {
      width: 6rem;
      height: 6rem;
      left: 66%;
      top: 75%;
      @media only screen and (min-width: 420px) {
        left: 37%;
      }
      @media only screen and (min-width: $tablet-breakpoint) {
        left: 26%;
      }
      @media only screen and (min-width: $desktop-breakpoint) {
        left: 88%;
      }
      @media only screen and (min-width: $large-desktop-breakpoint) {
        left: 68%;
      }
    }
  }
  .carousel__buttons {
    display: flex;
    justify-content: flex-start;
    width: 50%;
    margin: 1rem 0rem;
  }
}
