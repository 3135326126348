@use "../../assets/sass/abstracts/variables" as *;
@use "../../assets/sass/layout/layout" as *;
@use "../../assets/sass/abstracts/mixin" as *;
@use "../../assets/sass/base/resets" as *;

#home {
  min-height: 100vh;
  @media only screen and (min-width: $tablet-breakpoint) {
    min-height: 90vh;
  }
  .scroll {
    text-align: center;
    grid-area: 8/6/8/8;
    align-self: center;
    animation-name: bounce;
    animation-timing-function: ease;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    .scroll__image {
      mix-blend-mode: hard-light;
      width: 2rem;
      height: 2rem;
    }
    .scroll__text {
      color: $color-dark-grey;
      font-family: $font-accent;
    }
  }
  .hero {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    grid-area: 1/1/8/13;
    @media only screen and (min-width: $tablet-breakpoint) {
      flex-direction: row-reverse;
      justify-content: center;
    }
    .hero__image {
      width: 50%;
      padding: 2rem;
      //Animation styles:
      opacity: 0;
      animation: 3s ease-in-out 0s fadeIn;
      animation-fill-mode: forwards;
      animation-duration: 2s;
      @media only screen and (min-width: $tablet-breakpoint) {
        max-width: 35%;
      }
    }
    .hero__text {
      align-items: center;
      color: #3a5751;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      justify-content: flex-end;
      text-align: center;
      width: 70%;
      flex-grow: 2;
      @media only screen and (min-width: $tablet-breakpoint) {
        width: 50%;
        padding: 3rem;
        flex-grow: 0;
      }
      .hero__header {
        opacity: 0;
        animation: 2s ease-in-out 1.5s fadeIn;
        animation-fill-mode: forwards;
        grid-area: 2/3/2/10;
      }
      .hero__intro {
        font-family: $font-body;
        //Animation
        opacity: 0;
        animation: 3s ease-in-out 2s fadeIn;
        animation-fill-mode: forwards;
      }
      .hero__button {
        //Animation
        opacity: 0;
        animation: 3s ease-in-out 2.5s fadeIn;
        animation-fill-mode: forwards;
        align-self: center;
      }
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
