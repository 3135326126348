@use "../../assets/sass/abstracts/mixin" as *;
@use "../../assets/sass/abstracts/variables" as *;

.carousel__copy {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .carousel__header {
    width: 100%;
    height: 20%;
  }
  .carousel__text {
    width: 100%;
    color: $color-dark-grey;
    min-height: 70px;
  }

  .carousel__code-container {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;

    .carousel__code-icon {
      width: 2.5rem;
      height: 2.5rem;
    }
    .carousel__code-link {
      @include flex-center-column;
      gap: 0.15rem;
    }
    .carousel__code-label {
      font-size: 0.75rem;
    }
  }
}
